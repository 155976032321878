import { useMatches } from "@remix-run/react";
import type { ServerRuntimeMetaArgs } from "@remix-run/server-runtime";
import { useMemo } from "react";

const DEFAULT_REDIRECT = "/";

/**
 * This should be used any time the redirect path is user-provided
 * (Like the query string on our login/signup pages). This avoids
 * open-redirect vulnerabilities.
 * @param {string} to The redirect destination
 * @param {string} defaultRedirect The redirect to use if the to is unsafe.
 */
export function safeRedirect(
    to: FormDataEntryValue | string | null | undefined,
    defaultRedirect: string = DEFAULT_REDIRECT,
) {
    if (!to || typeof to !== "string") {
        return defaultRedirect;
    }

    if (!to.startsWith("/") || to.startsWith("//")) {
        return defaultRedirect;
    }

    return to;
}

/**
 * This base hook is used in other hooks to quickly search for specific data
 * across all loader data using useMatches.
 * @param {string} id The route id
 * @returns {JSON|undefined} The router data or undefined if not found
 */
export function useMatchesData(
    id: string,
): Record<string, unknown> | undefined {
    const matchingRoutes = useMatches();
    const route = useMemo(
        () => matchingRoutes.find((route) => route.id === id),
        [matchingRoutes, id],
    );
    return route?.data as Record<string, unknown>;
}

export function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
}

export function getRootTitle(
    matches: ServerRuntimeMetaArgs["matches"],
): string {
    return (
        matches
            .reverse()
            .map(
                ({ meta }) =>
                    meta.find(
                        (
                            m,
                        ): m is {
                            title: string;
                        } => "title" in m,
                    )?.title,
            )
            .filter(Boolean)[0] ?? ""
    );
}
